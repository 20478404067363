import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './../hooks/AuthContext';
import Splash from './../components/splash/Splash';

function UnprotectedRoute({ children }) {
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/', { replace: true });
    }
  }, []);

  return isAuthenticated ?  <Splash /> : children;
}

export default UnprotectedRoute;
