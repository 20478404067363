import { useEffect, useState } from 'react';
import './ProductGallary.css';

function ProductGallary(props) {
    const [slideIndex, setSlideIndex] = useState(0);

    useEffect(() => {
        showSlide(slideIndex);
    }, [slideIndex]);

    const moveSlide = (n) => {
        let i = slideIndex + n;
        i = i < 0 ? props.slides.length - 1 : i;
        i = i >= props.slides.length ? 0 : i;
        setSlideIndex(i);
        showSlide(slideIndex);
    };

    const currentSlide = (slideNum) => {
        setSlideIndex(slideNum);
        showSlide(slideIndex);
    };

    const showSlide = (slideNum) => {
        let slides = document.getElementsByClassName('mySlides');
        let dots = document.getElementsByClassName('dot');
        if (slideNum >= slides.length) {
            setSlideIndex(0);
        }    
        if (slideNum < 0) {
            setSlideIndex(props.slides.length - 1);
        }
        for (let i = 0; i < slides.length; i++) {
            slides[i].style.display = "none";  
        }
        for (let i = 0; i < dots.length; i++) {
            dots[i].className = dots[i].className.replace(" active", "");
        }
        console.log('##### slideIndex: ', slideIndex);
        slides[slideIndex].style.display = "block";  
        dots[slideIndex].className += " active";
    };

    return (
        <div className='prod-gallary'>
            <div className='slideshow-container'>
                {props.slides.map((slide, indexNum) => {
                    return (
                    <div className='mySlides slide-fade' key={indexNum}>
                        <img src={slide.imgUrl} alt={slide.imgName} />
                    </div>);
                })}
                <button className='prev' onClick={() => moveSlide(-1)}> ❮ </button>
                <button className='next' onClick={() => moveSlide(1)}> ❯ </button>
            </div>

            <div className='dots mt-3 text-center'>
                {props.slides.map((slide, indexNum) => <span className='dot' key={indexNum} onClick={() => currentSlide(indexNum)} />)}
            </div>
        </div>
    );
}

export default ProductGallary;