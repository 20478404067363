import axiosClient from './axios-client';

export function loginViaEmail(email) {
    const payload = { email };
    return axiosClient.post('/login/otp/email/send', payload);
}

export function verifyEmail(email, otp) {
    const payload = { email, otp };
    return axiosClient.post('/login/otp/email/verify', payload);
}

export function loginViaMobile(mobile) {
    const payload = { country_code: '+91', phone: mobile };
    return axiosClient.post('/login/otp/phone/send', payload);
}

export function verifyMobile(mobile, otp) {
    const payload = { country_code: '+91', phone: mobile, otp };
    return axiosClient.post('/login/otp/phone/verify', payload);
}

export function logoutUser() {
    return axiosClient.post('/logout');
}

export function getLoggedInUser() {
    return axiosClient.get('/auth/user');
}