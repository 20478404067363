import logo from './../../assets/logo/logo-white.png';
import './Splash.css'

function Splash() {
    return (
        <div className='splash-container'>
            <img className='splash-logo' src={logo} alt='logo'/>
            <h3 className='mt-3 splash-brand-name'>Club & Cart</h3>
            {/* <div className="loader"></div> */}
        </div>
    );
}

export default Splash;