import React, { createContext, useState, useContext } from 'react';
import StorageService from '../services/storage.service';
import { generateCsrfToken } from '../services/tokens.service';
import { setCsrfToken } from '../services/axios-client';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loggedInUser, setLoggedInUser] = useState({
    loggedInId: null,
    loggedInUsing: null,
  });

  const login = () => {
    setIsAuthenticated(true);
    generateCsrfToken()
      .then((response) => {
        setCsrfToken(response?.data?.token);
        StorageService.lStorage.setTokenCsrf(response?.data?.token);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const logout = () => {
    setIsAuthenticated(false);
    setCsrfToken(null);
    StorageService.lStorage.clearAll();
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated, loggedInUser, setLoggedInUser, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
