import axiosClient from './axios-client';

export function getPaymentsession(amount, description) {
    const payload = {
        amount: amount,
        currency: 'INR',
        meta_data: [
            {key: 'description', value: description}
        ]
    };
    return axiosClient.post('/agent/payment-sessions', payload);
}
