import ProgressBar from 'react-bootstrap/ProgressBar';
import './Products.css';

function Products() {
    return (
        <div className="products-container">
            <div className='product-container'>
                <div className='product-img'>
                    <img src={'https://vestirio.com/cdn/shop/files/007_1800x1800.webp?v=1690795694'} alt={''} />
                </div>
                <div className='product-name'>Some product</div>
                <div className='price-container'>
                    <span className='selling-price'>999.00</span> &ensp;
                    <span className='mrp-price'>1499.00</span>
                </div>
                <div className='lot-container'>
                    <span>60 lots left</span>
                    <ProgressBar now={60} label={`60 lots left`} />
                </div>
            </div>
            <div className='product-container'>
                <div className='product-img'>
                    <img src={'https://vestirio.com/cdn/shop/files/007_1800x1800.webp?v=1690795694'} alt={''} />
                </div>
                <div className='product-name'>Some product</div>
                <div className='price-container'>
                    <span className='selling-price'>₹ 999.00</span> &ensp;
                    <span className='mrp-price'>₹ 1499.00</span>
                </div>
                <div className='lot-container'>
                    <span>60 lots left</span>
                    <ProgressBar now={60} />
                </div>
            </div>
            <div className='product-container'>
                <div className='product-img'>
                    <img src={'https://vestirio.com/cdn/shop/files/007_1800x1800.webp?v=1690795694'} alt={''} />
                </div>
                <div className='product-name'>Some product</div>
                <div className='price-container'>
                    <span className='selling-price'>999.00</span> &ensp;
                    <span className='mrp-price'>1499.00</span>
                </div>
                <div className='lot-container'>
                    <span>60 lots left</span>
                    <ProgressBar now={60} />
                </div>
            </div>
        </div>
    );
}

export default Products;