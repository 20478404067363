export const CATEGORIES_MOCK_DATA = {
    "categories": [
        {
            "id": 1,
            "created_at": 1725360249.000000000,
            "name": "Electronics",
            "description": "Devices, gadgets, and accessories",
            "image_url": "http://example.com/electronics.jpg",
            "hidden": false,
            "sub_categories": [
                {
                    "id": 1,
                    "created_at": 1725360253.000000000,
                    "name": "Mobile Phones",
                    "description": "Latest smartphones and mobile devices",
                    "image_url": "http://example.com/mobile_phones.jpg",
                    "hidden": false
                },
                {
                    "id": 2,
                    "created_at": 1725360253.000000000,
                    "name": "Laptops",
                    "description": "Various brands of laptops and notebooks",
                    "image_url": null,
                    "hidden": false
                },
                {
                    "id": 3,
                    "created_at": 1725360253.000000000,
                    "name": "Mobile Phones",
                    "description": "Latest smartphones and mobile devices",
                    "image_url": "http://example.com/mobile_phones.jpg",
                    "hidden": false
                },
                {
                    "id": 4,
                    "created_at": 1725360253.000000000,
                    "name": "Laptops",
                    "description": "Various brands of laptops and notebooks",
                    "image_url": null,
                    "hidden": false
                },
                {
                    "id": 5,
                    "created_at": 1725360253.000000000,
                    "name": "Mobile Phones",
                    "description": "Latest smartphones and mobile devices",
                    "image_url": "http://example.com/mobile_phones.jpg",
                    "hidden": false
                },
                {
                    "id": 6,
                    "created_at": 1725360253.000000000,
                    "name": "Laptops",
                    "description": "Various brands of laptops and notebooks",
                    "image_url": null,
                    "hidden": false
                }
            ]
        },
        {
            "id": 2,
            "created_at": 1725360249.000000000,
            "name": "Books",
            "description": "Wide variety of books and literature",
            "image_url": "http://example.com/books.jpg",
            "hidden": false,
            "sub_categories": [
                {
                    "id": 3,
                    "created_at": 1725360253.000000000,
                    "name": "Fiction",
                    "description": "Popular fiction books",
                    "image_url": "http://example.com/fiction.jpg",
                    "hidden": false
                },
                {
                    "id": 4,
                    "created_at": 1725360253.000000000,
                    "name": "Non-Fiction",
                    "description": "Informative non-fiction books",
                    "image_url": "",
                    "hidden": false
                },
                {
                    "id": 5,
                    "created_at": 1725360253.000000000,
                    "name": "Fiction",
                    "description": "Popular fiction books",
                    "image_url": "http://example.com/fiction.jpg",
                    "hidden": false
                },
                {
                    "id": 6,
                    "created_at": 1725360253.000000000,
                    "name": "Non-Fiction",
                    "description": "Informative non-fiction books",
                    "image_url": "",
                    "hidden": false
                }
            ]
        },
        {
            "id": 3,
            "created_at": 1725360249.000000000,
            "name": "Clothing",
            "description": "Fashionable clothing and accessories",
            "image_url": "http://example.com/clothing.jpg",
            "hidden": false,
            "sub_categories": [
                {
                    "id": 5,
                    "created_at": 1725360253.000000000,
                    "name": "Men's Wear",
                    "description": "Stylish clothing for men",
                    "image_url": "http://example.com/mens_wear.jpg",
                    "hidden": false
                },
                {
                    "id": 6,
                    "created_at": 1725360253.000000000,
                    "name": "Women's Wear",
                    "description": "Trendy clothing for women",
                    "image_url": "http://example.com/womens_wear.jpg",
                    "hidden": false
                }
            ]
        },
        {
            "id": 4,
            "created_at": 1725360249.000000000,
            "name": "Sports",
            "description": "Sporting goods and equipment",
            "image_url": "http://example.com/sports.jpg",
            "hidden": true,
            "sub_categories": [
                {
                    "id": 7,
                    "created_at": 1725360253.000000000,
                    "name": "Outdoor Sports",
                    "description": "Equipment for outdoor activities",
                    "image_url": "http://example.com/outdoor_sports.jpg",
                    "hidden": true
                },
                {
                    "id": 8,
                    "created_at": 1725360253.000000000,
                    "name": "Indoor Sports",
                    "description": "Equipment for indoor sports",
                    "image_url": "http://example.com/indoor_sports.jpg",
                    "hidden": true
                }
            ]
        },
        {
            "id": 5,
            "created_at": 1725360249.000000000,
            "name": "Furniture",
            "description": "Home and office furniture",
            "image_url": "http://example.com/furniture.jpg",
            "hidden": false,
            "sub_categories": [
                {
                    "id": 9,
                    "created_at": 1725360253.000000000,
                    "name": "Living Room Furniture",
                    "description": "Furniture for living rooms",
                    "image_url": "http://example.com/living_room.jpg",
                    "hidden": false
                },
                {
                    "id": 10,
                    "created_at": 1725360253.000000000,
                    "name": "Office Furniture",
                    "description": "Furniture for office spaces",
                    "image_url": "http://example.com/office_furniture.jpg",
                    "hidden": false
                }
            ]
        }
    ]
};