const StorageService = {
    lStorage: {
        clearAll: () => {
            localStorage.clear();
        },
        setTokenCsrf: (token) => {
            localStorage.setItem('njvfnbf', token);
        },
        getTokenCsrf: () => {
            return localStorage.getItem('njvfnbf');
        }
    },
    sStorage: {}
};

export default StorageService;