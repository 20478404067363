import { useEffect, useState } from 'react';
import { RouterProvider } from 'react-router-dom';
import { getLoggedInUser } from './services/auth-handler.service';
import { setCsrfToken } from './services/axios-client';
import { useAuth } from './hooks/AuthContext';
import ROUTER_CONFIG from './RouterConfig';
import StorageService from './services/storage.service';
import Splash from './components/splash/Splash';

function App() {
  const {setLoggedInUser, setIsAuthenticated} = useAuth();
  const [userFetched, setUserFeched] = useState(false);

  useEffect(() => {
    getLoggedInUser()
      .then((response) => {
        const {created_using, email, phone} = response?.data;
        const loggedInId = created_using === 'email' ? email : phone;
        setLoggedInUser({
          loggedInId: loggedInId,
          loggedInUsing: created_using,
        });
        setIsAuthenticated(true);
        setUserFeched(true);
        const token = StorageService.lStorage.getTokenCsrf();
        if (token) {
          setCsrfToken(token);
        }
      })
      .catch((error) => {
        console.error(error);
        setUserFeched(true);
      });
  }, []);

  return userFetched ? <RouterProvider router={ROUTER_CONFIG} /> : <Splash />;
}

export default App;
