import React, { createContext, useContext, useState } from 'react';

const DataContext = createContext();
const DEFAULT_STATE = {
  token: '',
  login: {
    userLoginId: '',
    loginVia: ''
  }
};

export const DataProvider = ({ children }) => {
  const [data, setData] = useState(DEFAULT_STATE);

  return (
    <DataContext.Provider value={{ data, setData }}>
      {children}
    </DataContext.Provider>
  );
};

export const useData = () => {
  return useContext(DataContext);
};