import axios from 'axios';

const axiosClient = axios.create();

axiosClient.defaults.baseURL = 'https://api.clubandcart.com';
axiosClient.defaults.withCredentials = true;

export function setCsrfToken(token) {
    axiosClient.defaults.headers.common['X-CSRF-TOKEN'] = token;
}

export default axiosClient;