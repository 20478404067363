import * as ZPAY from "https://static.zohocdn.com/zpay/zpay-js/v1/zpayments.js";
import { useState } from "react";
import Alert from "@mui/material/Alert";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { CurrencyInput } from "react-currency-mask";
import { getPaymentsession } from "../../services/payment.service";
import CncNavBar from "../nav-bar/NavBar";
import "./Payment.css";

const payments_config = {
  account_id: "60034020706",
  domain: "IN",
  otherOptions: {
    api_key:
      "1003.a622cfb88c174ed369f49563057b8dd3.9632f926ab78d40b922fc789ebe0e16c",
  },
};
const instance = new window.ZPayments(payments_config);

function Payment() {
  const [paymentAmount, setPaymentAmount] = useState("");
  const [paymentDescription, setPaymentDescription] = useState("");
  const [valError, setValError] = useState(false);
  const [paySessionError, setPaySessionError] = useState(false);

  const requestPayment = () => {
    if (paymentAmount && paymentDescription) {
      setValError(false);
      getPaymentsession(paymentAmount.toFixed(2), paymentDescription)
        .then((response) => {
          setPaySessionError(false);
          proceedToPaymentPage(response.data.payment_session);
        })
        .catch((error) => {
          setPaySessionError(true);
        });
    } else {
      setValError(true);
    }
  };

  const proceedToPaymentPage = async (session) => {
    try {
      const options = {
        amount: session.amount.toString(),
        currency_code: session.currency,
        payments_session_id: session.payment_session_id,
        currency_symbol: '₹',
        business: 'Clubncart',
        description: session.created_time.toString(),
      };

      let data = await instance.requestPaymentMethod(options);
      //   const {payment_id, message} = data;
      // SUCCESS MESSAGE HERE
    } catch (error) {
      if (error.code !== 'widget_closed') {
        // CLOSE & DISPLAY ERROR
      }
    } finally {
      await instance.close();
    }
  };

  return (
    <>
      <CncNavBar></CncNavBar>
      <Card sx={{ maxWidth: 360, margin: "36px auto" }}>
        <CardContent>
          <Box sx={{ "& button": { m: 1 } }}>
            <h1 className="text-center">Collect Payment</h1>
            <CurrencyInput
              locale="INR"
              currency="INR"
              onChangeValue={(event, originalValue, maskedValue) => {
                setPaymentAmount(originalValue);
              }}
              InputElement={
                <TextField
                  fullWidth
                  required
                  id="payment-amount"
                  className="mt-3"
                  label="Amount"
                />
              }
            />
            <TextField
              fullWidth
              required
              id="payment-description"
              className="mt-3"
              label="Description"
              onChange={(event) => setPaymentDescription(event.target.value)}
            />
            <Button
              fullWidth
              className="mt-3 btn-mlr-0"
              variant="contained"
              size="large"
              onClick={requestPayment}
            >
              Collect Payment
            </Button>
            {valError ? (
              <Alert severity="error" className="mt-3">
                Please enter amount and description.
              </Alert>
            ) : null}
            {paySessionError ? (
              <Alert variant="filled" severity="error">
                Something went wrong, Please try again later.
              </Alert>
            ) : null}
          </Box>
        </CardContent>
      </Card>
    </>
  );
}

export default Payment;
