import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ProgressBar from 'react-bootstrap/ProgressBar';
import ProductGallary from '../../components/product-gallary/ProductGallary';
import './Product.css';

const SLIDES = [
    {
        imgUrl: 'https://m.media-amazon.com/images/I/71jAhKYD2QL._SL1500_.jpg',
        imgName: 'sample 1'
    },
    {
        imgUrl: 'https://m.media-amazon.com/images/I/71IRSb-k0QL._SL1500_.jpg',
        imgName: 'sample 2'
    },
    {
        imgUrl: 'https://m.media-amazon.com/images/I/819VEKLFYhL._SL1500_.jpg',
        imgName: 'sample 3'
    },
    {
        imgUrl: 'https://m.media-amazon.com/images/I/81C0ziD50XL._SL1500_.jpg',
        imgName: 'sample 4'
    },
    {
        imgUrl: 'https://m.media-amazon.com/images/I/81dV3HfgAoL._SL1500_.jpg',
        imgName: 'sample 5'
    }
];

function Product() {
    return (
        <div className='prod-container'>
            <ProductGallary slides={SLIDES} />
            <div className='prod-details'>
                <p className='prod-brand'>Calvin Clein</p>
                <p className='prod-brand'>Calvin Clein Regular fit slim fit shirt</p>
                <Row className='align-item-center'>
                    <Col>
                        <span className='prod-selling-price'>र 999</span> &ensp;
                        <span className='prod-mrp-price'>र 1999</span> &ensp;
                        <span className='prod-discount-percentage'>50%</span>
                    </Col>
                    <Col><ProgressBar now={60} label={`60 lots left`} /></Col>
                </Row>
            </div>
        </div>
    );
}

export default Product;