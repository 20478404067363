import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import OtpInput from 'react-otp-input';
import Countdown, { zeroPad } from 'react-countdown';
import { useAuth } from '../../hooks/AuthContext';
import { useData } from '../../hooks/DataContext';
import { loginViaEmail, verifyEmail, loginViaMobile, verifyMobile } from '../../services/auth-handler.service';
import './OtpVerification.css';

function OtpVerification() {
    const { data } = useData();
    const { login } = useAuth();
    const [otp, setOtp] = useState('');
    const [disableInput, setDisableInput] = useState(false);
    const [timer, setTimer] = useState(Date.now() + 60000);
    const [disableResendLink, setDisableResendLink] = useState(true);
    const navigate = useNavigate();
    let countdownApi;

    const setRef = (countdown) => {
        if (countdown) {
            countdownApi = countdown.getApi();
        }
    };

    const submitOtp = (otp) => {
        if (otp.length === 6) {
            setDisableInput(true);
            const isEmailLogin = data.login.loginVia === 'email';
            verifyOtp(otp, isEmailLogin ? verifyEmail : verifyMobile);
        }
    };

    const verifyOtp = (otp, verifyCb) => {
        countdownApi.pause();
        verifyCb(data.login.userLoginId, otp)
            .then((response) => {
                login();
                navigate('/', { replace: true });
            })
            .catch((error) => {
                setDisableInput(false);
                countdownApi.start();
                console.error(error);
            });
    };

    const countDownRenderer = ({ minutes, seconds }) => {
        return <div className='text-center mb-4 timer'>{zeroPad(minutes)}:{zeroPad(seconds)}</div>;
    };

    const onTimerComplete = () => {
        setDisableResendLink(false);
    }

    const resendOtp = () => {
        setDisableResendLink(true);
        setTimer(Date.now() + 60000);
        const isEmailLogin = data.login.loginVia === 'email';
        if (isEmailLogin) {
            loginViaEmail(data.login.userLoginId);
        } else {
            loginViaMobile(data.login.userLoginId);
        }
    };

    return (
        <div className='p-4 verification-container'>
            <h2>Enter OTP</h2>
            <p className="otp-sent mt-4 mb-4">Please enter the OTP sent to <br />{data.login.userLoginId}</p>
            <OtpInput numInputs={6} inputType={'text'}
                renderInput={(props) => <input disabled={disableInput} {...props} />}
                value={otp} onChange={(value) => {
                    setOtp(value);
                    submitOtp(value);
                }}
                containerStyle="otp-container mb-4" inputStyle="otp-box" >
            </OtpInput>
            <Countdown date={timer} key={timer} ref={setRef} renderer={countDownRenderer} onComplete={onTimerComplete} />
            <p className='text-center'>Didn't get it? <button className='btn btn-link p-0 text-decoration-none cnc-text-col-rose' disabled={disableResendLink} onClick={resendOtp}>Resend OTP</button></p>
        </div>
    );
}

export default OtpVerification;