import { createBrowserRouter } from 'react-router-dom';
import ProtectedRoute from './components/ProtectedRoute';
import UnprotectedRoute from './components/UnprotectedRoute';
import Home from './pages/home/Home';
import Login from './pages/login/Login';
import OtpVerification from './pages/otp-verification/OtpVerification';
import Search from './pages/search/Search';
import Settings from './pages/settings/Settings';
import Products from './pages/products/Products';
import Product from './pages/product/Product';
import Payment from './components/payments/Payment';

const ROUTER_CONFIG = createBrowserRouter([
    {
      path: '/',
      element: <ProtectedRoute><Home /></ProtectedRoute>,
    },
    {
      path: '/login',
      element:<UnprotectedRoute><Login /></UnprotectedRoute>,
    },
    {
      path: '/otp-verification',
      element: <UnprotectedRoute><OtpVerification /></UnprotectedRoute>,
    },
    {
      path: '/search',
      element: <ProtectedRoute><Search /></ProtectedRoute>,
    },
    {
      path: '/settings',
      element: <ProtectedRoute><Settings /></ProtectedRoute>,
    },
    {
      path: '/products',
      element: <ProtectedRoute><Products /></ProtectedRoute>,
    },
    {
      path: '/product',
      element: <ProtectedRoute><Product /></ProtectedRoute>,
    },
    {
      path: '/payment',
      element: <ProtectedRoute><Payment /></ProtectedRoute>,
    },
  ]);

  export default ROUTER_CONFIG;