import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './../hooks/AuthContext';
import Splash from './../components/splash/Splash';

function ProtectedRoute({ children }) {
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/login');
    }
  }, []);

  return isAuthenticated ? children : <Splash />;
}

export default ProtectedRoute;
