import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import Button from "@mui/material/Button";
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import { useAuth } from "../../hooks/AuthContext";
import { useData } from '../../hooks/DataContext';
import { generateCsrfToken } from './../../services/tokens.service';
import { setCsrfToken } from './../../services/axios-client';
import { loginViaEmail, loginViaMobile } from './../../services/auth-handler.service';
import logo from './../../assets/logo/logo.png';
import './Login.css';

function Login() {
    const navigate = useNavigate();
    const { data, setData } = useData();
    const [userLoginId, setUserLoginId] = useState('');
    const [disableBtn, setDisableBtn] = useState(false);
    const { isAuthenticated } = useAuth();

    useEffect(() => {
        checkForCsrfToken();
    }, []);

    const checkForCsrfToken = () => {
        if (!isAuthenticated) {
            generateCsrfToken()
                .then((response) => {
                    setCsrfToken(response?.data?.token);
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    };

    const handleSubmit = () => {
        setDisableBtn(true);
        const isLoginIdMobileNumber = !isNaN(userLoginId);
        navigateToOtpScreen(isLoginIdMobileNumber, isLoginIdMobileNumber ? loginViaMobile : loginViaEmail);
    };

    const navigateToOtpScreen = (isLoginIdMobileNumber, loginCb) => {
        loginCb(userLoginId)
            .then((response) => {
                setData({
                    ...data,
                    login: { userLoginId, loginVia: isLoginIdMobileNumber ? 'phone' : 'email' }
                });
                navigate('/otp-verification', { replace: true });
            })
            .catch((error) => {
                setDisableBtn(false);
                console.error(error);
            });
    };

    return (
        <div className='login-container'>
            <img className='logo m-4' src={logo} alt='logo'/>
            <h2 className='mb-4 cnc-text-col-primary'>Grab Beyond Limits</h2>
            <Container maxWidth="sm">
                <TextField fullWidth label="Enter email or mobile number" onChange={(e) => setUserLoginId(e.target.value)} />
                <Button fullWidth className="mt-3 btn-mlr-0" variant="contained" size="large" onClick={handleSubmit} disabled={disableBtn}>Get OTP</Button>
                <p className='mt-4 text-center'>
                    By Continuing, you agree to our <a className="cnc-text-col-rose text-decoration-none" href='/terms-of-use'>Terms of Use</a> & <a className="cnc-text-col-rose text-decoration-none" href='/privacy-policy'>Privacy Policy</a>.
                </p>
            </Container>
        </div>
    );
}

export default Login;