import { useEffect, useState } from 'react';
import Stack from 'react-bootstrap/Stack';
import CncNavBar from '../../components/nav-bar/NavBar';
import HeroCarousel from '../../components/hero-carousel/HeroCarousel';
import { getAllCategories } from '../../services/products.service';
import no_image from './../../assets/images/default-no-image.png';
import './Home.css';

function Home() {
  const [categories, setCategories] = useState([]);
  useEffect(() => {
    getAllCategories()
      .then((response) => {
        setCategories(response.categories);
      })
      .catch((error) => {
        console.log('##### ERROR: ', error)
      });
  }, []);

  return (
    <>
      <CncNavBar />
      <div className="home-container">
        <HeroCarousel />
        <h3 className="text-center cnc-text-col-violet m-3">Categories</h3>
        <div className="all-categories-container">
          {categories?.map((category) => {
            return (
              <div key={category.id}>
                <h4>{category.name}</h4>
                <Stack direction="horizontal" gap={3} className='categories-stack'>
                  {category.sub_categories?.map((subCategory) => {
                    return (
                      <div
                        key={subCategory.id}
                        className="category-container text-center"
                      >
                        <div className="category-img-container">
                          <img
                            src={subCategory.image_url || no_image}
                            alt={subCategory.name}
                          />
                        </div>
                        <span className="mt-2 category-name cnc-text-col-rose">
                          {subCategory.name}
                        </span>
                      </div>
                    );
                  })}
                </Stack>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default Home;
